import React from 'react'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import MobHomeHeader from '../../components/MobHomeHeader'

function CounsellorHomePage() {
    return (
        <>
            <MobHomeHeader />
            <HomeNavbar />
            <HomeMainSection
                title="Guide Students Toward Success in Education"
                subtitle="Assist students smoothly through their applications process and provide invaluable support every step of the way"
            />
            <HomeFooter />
        </>
    )
}

export default CounsellorHomePage