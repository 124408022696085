import React from 'react'

function PrivacyMainSection() {
    return (
        <section >
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12 text-center">
                        <h2 className="title ">Terms & Privacy</h2>
                    </div>
                    <div className="col-12">
                        <ul className="nav nav-pills justify-content-center" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="students-tab" data-bs-toggle="pill" data-bs-target="#students" aria-controls="students" aria-selected="true">Terms of Use</a>
                            </li>
                            <li className="nav-item ms-1" role="presentation">
                                <a className="nav-link" id="recruiters-tab" data-bs-toggle="pill" data-bs-target="#recruiters" aria-controls="recruiters" aria-selected="false">Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-xl-12 mt-5">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="students" role="tabpanel" aria-labelledby="students-tab" tabIndex="0">
                                <div className="row  gx-5 clearfix">
                                    <div className="col-12 col-lg-12">
                                        <div className="works-grid  clearfix">
                                            <ul className="TermsAndPrivacy">
                                                <li>
                                                    <h3>Introduction</h3>
                                                    <p>
                                                        These Terms of Use govern your use of the services provided by Edbucket Private Limited ("Edbucket," "we," "us," or "our"), including but not limited to our website, mobile applications, and any other products or services offered by Edbucket (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms of Use.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Use of Services</h3>
                                                    <p>
                                                        <ul className="TermsAndPrivacy">
                                                            <li className="list-style-initial">You must be at least 13 years old to use our Services.</li>
                                                            <li className="list-style-initial">You agree to use the Services only for lawful purposes and in accordance with these Terms of Use and all applicable laws and regulations.</li>
                                                        </ul>
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Privacy</h3>
                                                    <p>
                                                        Our Privacy Policy explains how we collect, use, and disclose information about you when you access or use our Services. By using our Services, you consent to our collection, use, and disclosure of your information as described in the Privacy Policy.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Intellectual Property</h3>
                                                    <p>
                                                        The content, features, and functionality of the Services are owned by Edbucket and are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any portion of the Services without our express written consent.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>User Accounts</h3>
                                                    <p>
                                                        You may need to create an account to access certain features of the Services. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Third-Party Content</h3>
                                                    <p>
                                                        Our Services may contain links to third-party websites or resources. We are not responsible for the content, products, or services offered by third parties, and your use of such websites or resources is at your own risk.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Disclaimer of Warranties</h3>
                                                    <p>
                                                        We make no warranties or representations about the accuracy or completeness of the content provided through the Services. Your use of the Services is at your own risk.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Limitation of Liability</h3>
                                                    <p>
                                                        In no event will Edbucket be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Services.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Governing Law</h3>
                                                    <p>
                                                        These Terms of Use are governed by and construed in accordance with the laws of United Kingdom, without giving effect to any principles of conflicts of law.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Changes to Terms of Use</h3>
                                                    <p>
                                                        We reserve the right to modify or revise these Terms of Use at any time. Any changes will be effective immediately upon posting the updated Terms of Use on the Services. Your continued use of the Services after any such changes constitutes your acceptance of the updated Terms of Use.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Contact Us</h3>
                                                    <p>
                                                        For any complains please email us on <a href="mailto:info@edbucket.com">info@edbucket.com</a> and one of our team members will get back to you.
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="recruiters" role="tabpanel" aria-labelledby="recruiters-tab" tabIndex="0">
                                <div className="row justify-content-center gx-5 clearfix">
                                    <div className="col-12 col-lg-12">
                                        <div className="works-grid clearfix">
                                            <div className="justify-text mb-3">
                                                Edbucket Private Limited ("Edbucket," "we," "us," or "our") is committed to protecting the privacy of individuals who use our services. This Privacy Policy explains how we collect, use, and disclose information about you when you access or use our website, mobile applications, and any other products or services offered by Edbucket (collectively, the "Services").
                                            </div>
                                            <ul className="TermsAndPrivacy">
                                                <li>
                                                    <h3>Information We Collect</h3>
                                                    <p>
                                                        <ul className="TermsAndPrivacy">
                                                            <li className="list-style-initial">We collect information you provide directly to us, such as when you create an account, fill out a form, or communicate with us.</li>
                                                            <li className="list-style-initial">We also collect information automatically when you use our Services, such as your IP address, device type, and browsing behavior.</li>
                                                            <li className="list-style-initial">We may collect information from third-party sources, such as social media platforms, if you choose to connect your account with such services.</li>
                                                        </ul>
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>How We Use Your Information</h3>
                                                    <p>
                                                        <ul>
                                                            <li className="list-style-initial">We use the information we collect to provide, maintain, and improve our Services, as well as to communicate with you and personalize your experience.</li>
                                                            <li className="list-style-initial">We may also use your information to analyze usage trends and preferences, and to comply with legal obligations.</li>
                                                        </ul>
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Sharing of Your Information</h3>
                                                    <p>
                                                        <ul>
                                                            <li className="list-style-initial">We may share your information with third-party service providers who help us operate our Services or fulfill your requests.</li>
                                                            <li className="list-style-initial">We may also share your information in response to a legal request or to protect our rights, property, or safety, or the rights, property, or safety of others.</li>
                                                        </ul>
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Your Choices</h3>
                                                    <p>
                                                        <ul>
                                                            <li className="list-style-initial">You may choose not to provide certain information, but this may limit your ability to use certain features of the Services.</li>
                                                            <li className="list-style-initial">You can opt out of receiving promotional communications from us by following the instructions provided in such communications.</li>
                                                        </ul>
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Security</h3>
                                                    <p>
                                                        We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Children's Privacy</h3>
                                                    <p>
                                                        Our Services are not directed to children under the age of 13, and we do not knowingly collect personal information from children under this age.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Changes to this Privacy Policy</h3>
                                                    <p>
                                                        We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Services.
                                                    </p>
                                                </li>

                                                <li>
                                                    <h3>Contact Us</h3>
                                                    <p>For any complains please email us on <a href="mailto:info@edbucket.com">info@edbucket.com</a> and one of our team members will get back to you.</p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PrivacyMainSection