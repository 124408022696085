import React from 'react'
import HomeNavbar from '../../components/HomeNavbar'
import HomeFooter from '../../components/HomeFooter'
import PrivacyMainSection from '../../components/PrivacyMainSection'



function Privacy() {
    return (
        <div>
            <HomeNavbar />
            <PrivacyMainSection />
            <HomeFooter />

        </div>
    )
}

export default Privacy