
import HomeNavbar from '../../components/HomeNavbar'
import HomeFooter from '../../components/HomeFooter'
import AboutUsMainSection from '../../components/AboutUsMainSection'

function AboutUs() {
    return (
        <div>
            <HomeNavbar />
            <AboutUsMainSection />
            <HomeFooter />
        </div>
    )
}

export default AboutUs