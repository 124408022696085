import React from 'react'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import MobHomeHeader from '../../components/MobHomeHeader'


function Homepage() {

    return (
        <>
            <MobHomeHeader />
            <HomeNavbar />
            <HomeMainSection
                title="Fulfil your dreams to study in the UK, US and Australia"
                subtitle="Explore, prepare, apply. Ed will help you at each step. We make it simple for you!"
            />
            <HomeFooter />
        </>

    )
}

export default Homepage