import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { RxHamburgerMenu } from "react-icons/rx";
// import LoginModal from '../Modals/LoginModal';
// import RegisterModal from '../Modals/RegisterModal';
// import { useDispatch, useSelector } from 'react-redux';
// import { setActiveTab } from '../../@redux/actions/config';

function MobHomeHeader() {
    // const logoUrl = useSelector(state => state.fetch_dns?.fetch_dns_record?.logoUrl)
    const [offcanvasOpen, setOffcanvasOpen] = useState(false);
    // console.log("Logo url for home page is", logoUrl)

    const [profile, setProfile] = useState("student")
    // const dispatch = useDispatch();
    const handleNavLinkClick = (newProfile) => {
        setProfile(newProfile);
        console.log("New nav link I am getting as ", newProfile)
        // dispatch(setActiveTab(newProfile))
    };
    console.log("profile :", profile)

    const toggleOffcanvas = () => {
        setOffcanvasOpen(prevState => !prevState);
    };

    // Function to close offcanvas
    const closeOffcanvas = () => {
        setOffcanvasOpen(false);
    };


    return (


        <header className=" bg-white d-xl-none py-3 sticky-top px-3">
            <div className='row'>
                <div className='col-2'>
                    <img src='images/profile-pic.svg' alt='profile-pic' />
                </div>
                <div className='col-8 text-center'>
                    <img src='images/logo.png' alt='logo-pic' />
                </div>
                <div className='col-2 text-end'>
                    <Link
                        className="btn-toggle"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#staticBackdrop"
                        aria-controls="staticBackdrop"
                        onClick={toggleOffcanvas}
                    >
                        <RxHamburgerMenu size={30} />
                    </Link>

                    <div
                        className={`offcanvas offcanvas-start ${offcanvasOpen ? 'show' : ''}`}
                        data-bs-backdrop="static"
                        tabIndex={-1}
                        id="staticBackdrop"
                        aria-labelledby="staticBackdropLabel"
                    >
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="staticBackdropLabel">
                                <Link className="navbar-brand" to="index">
                                    <img
                                        src="images/logo.png"
                                        className="img-fluid"
                                        alt="Image goes here"
                                    />
                                </Link>
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                // data-bs-dismiss="offcanvas"
                                aria-label="Close"
                                onClick={closeOffcanvas}
                            />
                        </div>
                        <div className="offcanvas-body">
                            <ul className="navbar-nav text-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/" onClick={() => handleNavLinkClick("student")} >
                                        For Students
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link " aria-current="page" to="/recruiters" onClick={() => handleNavLinkClick("agent")} >
                                        For Recruiters
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" aria-current="page" to="/councellors" onClick={() => handleNavLinkClick("counsellor")} >
                                        For Councellors
                                    </NavLink>
                                </li>
                                <button
                                    className="btn btn-purple"

                                >
                                    <a href="https://apply.edbucket.com/" target='_blank' style={{ color: 'white' }}>
                                        Get Started
                                    </a>

                                </button>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>

        </header>




    )
}

export default MobHomeHeader
