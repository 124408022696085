import React from 'react'
import HomeNavbar from '../../components/HomeNavbar'
import HomeMainSection from '../../components/HomeMainSection'
import HomeFooter from '../../components/HomeFooter'
import MobHomeHeader from '../../components/MobHomeHeader'

function AgentHomePage() {
    return (
        <>
            <MobHomeHeader />
            <HomeNavbar />
            <HomeMainSection
                title="Empower Students to Reach Their Educational Goals"
                subtitle="Open doors to global opportunities and make studying abroad a reality for your students"
            />
            <HomeFooter />
        </>
    )
}

export default AgentHomePage