import React from 'react'

function AboutUsMainSection() {
    return (
        <section id='about-us'>
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12">
                        <div className='text-center'>
                            <h2 className="title ">About Us</h2>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    Edbucket is a London based Ed-tech start-up solving the challenges in the current international student recruitment market.
                                </p>
                                <p>
                                    A trusted recruitment partner helping international students through our global offices and innovative online platform to select the right Universities and study in the UK, US, Europe, Australia and Canada.
                                </p>
                                <p>
                                    Online platforms are now becoming a little crowded and traditional recruitment business is limited to certain geographies only.
                                </p>
                                <p>
                                    This is where Edbucket differentiates from the current services providers. We have the advantages of recruiting students online and from our multi geography offices.
                                </p>
                                <p>
                                    Edbucket is also bringing banking and payment solutions under the platform.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <ul className="nav nav-pills justify-content-center" id="hiwtab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="students-tab" data-bs-toggle="pill" data-bs-target="#students" aria-controls="students" aria-selected="true">Mission</a>
                            </li>
                            <li className="nav-item ms-1" role="presentation">
                                <a className="nav-link" id="recruiters-tab" data-bs-toggle="pill" data-bs-target="#recruiters" aria-controls="recruiters" aria-selected="false">Vision</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-xl-12">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="students" role="tabpanel" aria-labelledby="students-tab" tabIndex="0">
                                <div className="row justify-content-center gx-5 clearfix">
                                    <div className="col-12 col-lg-12">
                                        <div className="works-grid clearfix">
                                            <h3>Providing International students the best opportunity to achieve their dream of studying abroad by removing their greatest challenges.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="recruiters" role="tabpanel" aria-labelledby="recruiters-tab" tabIndex="0">
                                <div className="row justify-content-center gx-5 clearfix">
                                    <div className="col-12 col-lg-12">
                                        <div className="works-grid clearfix">
                                            <h3>To create an easy and stress-free admission process for the International students.</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUsMainSection