import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LiaFacebookF } from "react-icons/lia";
import logo from '../../assets/images/Logo/logo.svg'
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";

function HomeFooter() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (

        <footer className='bg-blue'>
            <div className="container">
                <div className="row clearfix">
                    <div className="col-12 col-md-12 col-lg-3">
                        <img src={logo} width={151} height={25} className='mb-5' />
                        <a href="mailto:apply@edbucket.com" className="action-links">
                            apply@edbucket.com
                        </a>
                        <a href="tel:+44 2032903917" className="action-links">
                            +44 2032903917
                        </a>

                        <ul className="h-links">
                            <li>
                                <Link to="#" target="_blank" className="temp">
                                    <LiaFacebookF style={{ color: 'white' }} size={20} />
                                </Link>
                            </li>

                            <li>
                                <Link to="#" target="_blank" className="temp">
                                    <BsInstagram style={{ color: 'white' }} size={20} />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank" className="temp">
                                    <BsTwitter style={{ color: 'white' }} size={20} />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank" className="temp">
                                    <BsYoutube style={{ color: 'white' }} size={20} />
                                </Link>
                            </li>
                            <li>
                                <Link to="#" target="_blank" className="temp">
                                    <FaTelegramPlane style={{ color: 'white' }} size={20} />
                                </Link>
                            </li>
                        </ul>

                        <div className=''>
                            <p className="legal-text">copyright © 2024 Edbucket Ltd.</p>
                        </div>

                    </div>
                    {/* <div className="col-12 col-md-6 col-lg-5">
                        <div className='row'>
                            <div className='col-6'>
                                <div className="ft-title">London (UK)</div>
                                <p className="action-links" style={{ fontSize: '0.9rem' }}>idea london, 69 wilson st, london, EC2A 2BB</p>
                            </div>

                            <div className='col-6'>
                                <div className="ft-title">Delhi (India)</div>
                                <p className="action-links" style={{ fontSize: '0.9rem' }}> G-36, g-block outer circle, connaught place, delhi</p>
                            </div>


                            <div className='col-6'>
                                <div className="ft-title">Ahmedabad (India)</div>
                                <p className="action-links" style={{ fontSize: '0.9rem' }}>202, Addor Aspire, University Road, Panjarapole, Ambawadi, Ahmedabad - 380015</p>
                            </div>


                            <div className='col-6'>
                                <div className="ft-title">Kolkata (India)</div>
                                <p className="action-links" style={{ fontSize: '0.9rem' }}> 512, 5th Floor, Terminus Bldg, Plot BG 12/1B, New Town, Kolkata 700156</p>
                            </div>
                        </div>


                    </div>

                    <div className="col-6 col-md-3 col-lg-1 ">
                        <div className="ft-title">Others</div>
                        <ul className="ft-links clearfix">
                            <li>
                                <Link to="/about-us" className="temp" onClick={scrollToTop}>
                                    about us
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2">
                        <div className="ft-title">Legal</div>
                        <ul className="ft-links clearfix">
                            <li>
                                <Link to="/privacy" className="temp" onClick={scrollToTop}>
                                    terms & privacy
                                </Link>
                            </li>

                        </ul>
                    </div> */}



                    <div className="col-12 col-md-6 col-lg-5">
                        <div className='ft-title'>
                            Offices
                        </div>

                        <div className="ft-title" style={{ marginBottom: '0.3rem', fontSize: '0.9rem' }}>London (UK)</div>
                        <p className="action-links" style={{ fontSize: '0.9rem', marginBottom: '0.7rem', }}>Idea London, 69 Wilson St, London, EC2A 2BB</p>

                        <div className="ft-title" style={{ marginBottom: '0.3rem', fontSize: '0.9rem' }}>Delhi (India)</div>
                        <p className="action-links" style={{ fontSize: '0.9rem', marginBottom: '0.7rem' }}> G-36, g-block Outer Circle, Connaught Place, Delhi</p>


                        <div className="ft-title" style={{ marginBottom: '0.3rem', fontSize: '0.9rem' }}>Ahmedabad (India)</div>
                        <p className="action-links" style={{ fontSize: '0.9rem', marginBottom: '0.7rem' }}>202, Addor Aspire, University Road, Panjarapole, Ambawadi, Ahmedabad - 380015</p>


                        <div className="ft-title" style={{ marginBottom: '0.3rem', fontSize: '0.9rem' }}>Kolkata (India)</div>
                        <p className="action-links" style={{ fontSize: '0.9rem', marginBottom: '0.7rem' }}> 511, 5th Floor, Terminus Bldg, Plot BG 12/1B, New Town, Kolkata 700156</p>
                    </div>

                    <div className="col-12 col-md-3 col-lg-4 ">
                        <div className='d-flex'>
                            <div className='col-lg-6'>
                                <div className="ft-title">Others</div>
                                <ul className="ft-links clearfix">
                                    <li>
                                        <Link to="/about-us" className="temp" onClick={scrollToTop}>
                                            About us
                                        </Link>
                                    </li>
                                </ul>

                            </div>

                            <div className='col-lg-6'>
                                <div className="ft-title">Legal</div>
                                <ul className="ft-links clearfix">
                                    <li>
                                        <Link to="/privacy" className="temp" onClick={scrollToTop}>
                                            Terms & Privacy
                                        </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        <div className="" >
                            <a href='https://www.airc-education.org/airc-certified-agencies' target='#blank' className='action-links' style={{ fontSize: '0.95rem' }}>Comments about Edbucket for the American International Recruitment Council</a>

                        </div>
                        {/* <div className='text-center'> */}
                        <img src='images/footer_logo.gif' height={80} width={80} />
                        {/* </div> */}
                    </div>

                    {/* <div className="col-12 col-lg-12 text-center">
                        <p className="legal-text">copyright © 2024 Edbucket Ltd.</p>
                    </div> */}
                </div>
            </div>
        </footer>



    )
}

export default HomeFooter